import type { SxProps } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';

import Tabs, { getTabsUtilityClass } from 'shared/ui/tabs/Tabs';
import { buttonClasses } from 'shared/ui/button/Button';

import type { TabProps } from './Tabs';

type Props = {
  activeTab?: string;
  keyToUrl?: (tabKey: string) => string;
  sx?: SxProps;
  tabs: TabProps[];
  variant?: 'fullWidth' | 'scrollable' | 'small' | 'standard' | 'vertical';
};

function NavigationTabs(props: Props) {
  const { tabs, keyToUrl, activeTab: value, variant, sx } = props;
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Tabs
      tabs={tabs}
      value={value ?? location.pathname}
      // @ts-expect-error MUI bug https://github.com/mui/material-ui/issues/33785
      variant={variant ?? undefined}
      sx={{
        ...NavigationTabsStyle,
        ...sx,
        ...(variant === 'scrollable' && {
          position: 'relative',
          [`& .${getTabsUtilityClass('scrollButtons')}:first-of-type`]: {
            // left scroll arrow
            position: 'absolute',
            bottom: 0,
            top: 0,
            bgcolor: 'grey.50',
            zIndex: 10,
            [`&:not(.${buttonClasses.disabled})`]: {
              opacity: 1,
            },
          },
        }),
      }}
      onChange={(_event, newValue) =>
        navigate(keyToUrl?.(newValue) ?? newValue)
      }
    />
  );
}

export const NavigationTabsStyle = {
  bgcolor: 'grey.50',
  borderBottom: '1px solid',
  borderColor: 'grey.300',
};

export default NavigationTabs;
