import { type ReactElement, useState } from 'react';

import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/material/styles';
import { skipToken } from '@reduxjs/toolkit/query';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';
import Modal from 'shared/components/modal/Modal';
import Alert from 'shared/ui/alert/Alert';

import ConfirmToChangeStatus from 'accruals/pages/clinical-expenses/shared/ConfirmToChangeStatus';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import type {
  BackendContractStatus,
  VersionHistoryRow,
} from 'shared/lib/types';
import { PeriodGridBlobType, statusFromBackendValue } from 'shared/lib/types';

import { useGetAllContractVersionsForContractContainerQuery } from 'shared/api/rtkq/contractcontainers';
import { useDeleteContractMutation } from 'shared/api/rtkq/contracts';
import { useUpdateContractStatusMutation } from 'shared/api/rtkq/contractstatuses';

import useContractAndPeriodWithVersions from '../../shared/hooks/useContractAndPeriodWithVersions';
import useCroVersionHistoryColDefs from '../hooks/useCroVersionHistoryColDefs';
import useCroVersionHistoryGridOptions from '../hooks/useCroVersionHistoryGridOptions';
import useCroVersionHistoryRows from '../hooks/useCroVersionHistoryRows';

type Props = { overlayNoRowsTemplate?: string; sx?: SxProps };

function CroVersionHistoryGrid(props: Props): ReactElement {
  const { overlayNoRowsTemplate, sx } = props;

  const { contractContainerTraceId, contractContainer, periodTraceId } =
    useContractAndPeriodWithVersions();
  const { currentData: contractVersions } =
    useGetAllContractVersionsForContractContainerQuery(
      contractContainerTraceId && periodTraceId
        ? { trace_id: contractContainerTraceId, otherParameter: periodTraceId }
        : skipToken,
    );
  const isContractStatusV2Enabled = useFeatureFlag('contract_status_v2');

  const [
    showStatusChangeConfirmationModal,
    setShowStatusChangeConfirmationModal,
  ] = useState(false);
  const [confirmationModalData, setConfirmationModalData] =
    useState<StatusChangeConfirmationModalData | null>(null);
  const [versionToDelete, setVersionToDelete] =
    useState<VersionHistoryRow | null>(null);
  const columnDefs = useGridColDefs(useCroVersionHistoryColDefs, [
    setShowStatusChangeConfirmationModal,
    setConfirmationModalData,
    setVersionToDelete,
  ]);
  const rowData = useCroVersionHistoryRows(contractContainer, contractVersions);
  const gridOptions = useGridOptions(useCroVersionHistoryGridOptions);

  const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false);
  const [updateContractStatus] = useUpdateContractStatusMutation();
  const [deleteContractVersion, { isLoading: isDeletingContractVersion }] =
    useDeleteContractMutation();

  const onStatusChangeAccepted = () => {
    if (!confirmationModalData) {
      return;
    }

    if (!isContractStatusV2Enabled) {
      void (async () => {
        await updateContractStatus({
          trace_id: confirmationModalData.statusTraceIdForPeriod,
          status: confirmationModalData.newStatus as BackendContractStatus,
        });
      })();
    }

    setShowSuccessMsg(true);
    setShowStatusChangeConfirmationModal(false);
  };

  const onStatusChangeRejected = () => {
    setShowStatusChangeConfirmationModal(false);
  };

  const handleCloseModal = () => setVersionToDelete(null);
  const handleContractDelete = async () => {
    const traceId = versionToDelete?.contract_version_trace_id;
    if (traceId) {
      await deleteContractVersion(traceId);
    }
    handleCloseModal();
  };

  return (
    <>
      {showStatusChangeConfirmationModal &&
        contractContainerTraceId &&
        confirmationModalData && (
          <ConfirmToChangeStatus
            contractContainerTraceId={contractContainerTraceId}
            existingEffectiveDate={confirmationModalData.existingEffectiveDate}
            existingVoidedDate={confirmationModalData.existingVoidedDate}
            contractVersionTraceId={
              confirmationModalData.contractVersionTraceId
            }
            currentStatus={statusFromBackendValue(
              confirmationModalData.currentStatus as BackendContractStatus,
            )}
            existingAipEffectiveDate={
              confirmationModalData.existingAipEffectiveDate
            }
            newStatus={statusFromBackendValue(
              confirmationModalData.newStatus as BackendContractStatus,
            )}
            onClose={onStatusChangeRejected}
            onConfirm={onStatusChangeAccepted}
          />
        )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={4000}
        open={showSuccessMsg}
        onClose={() => setShowSuccessMsg(false)}
      >
        <Alert severity="success">Contract status changed.</Alert>
      </Snackbar>
      <CondorAgGrid
        columnDefs={columnDefs}
        gridOptions={gridOptions}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        rowData={rowData}
        sx={sx}
      />
      {versionToDelete && (
        <Modal
          handleClose={handleCloseModal}
          title={`Are you sure you want to delete ${versionToDelete.version_name?.length ? versionToDelete.version_name : `this ${versionToDelete.version}`} for ${contractContainer?.vendor_name}?`}
          ButtonProps={{
            label: 'Delete Contract Version',
            testId: 'DeleteCROContractVersionModal',
            onClick: () => void handleContractDelete(),
            sx: { backgroundColor: 'error.main' },
            loading: isDeletingContractVersion,
          }}
          isOpen
        >
          <Typography>
            This will delete the contract record, budget, and files for this
            version. If you are deleting the current contract or
            amendment-in-progress, this will delete any expenses that were being
            used in the trial&apos;s accrual and forecasting calculations. This
            action cannot be undone.
          </Typography>
        </Modal>
      )}
    </>
  );
}

export type StatusChangeConfirmationModalData = {
  statusTraceIdForPeriod: string;
  contractVersionTraceId: string;
  contractContainerTraceId: string;
  newStatus: string;
  currentStatus: string;
  versionName: string;
  existingEffectiveDate?: string;
  existingAipEffectiveDate?: string;
  existingVoidedDate?: string;
};

export default withPeriodSpecificGridWrapper(
  CroVersionHistoryGrid,
  PeriodGridBlobType.CRO_VERSION_HISTORY,
);
