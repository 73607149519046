import type { ChangeEvent } from 'react';
import { memo, useEffect, useMemo, useState } from 'react';

import Cancel from '@mui/icons-material/Cancel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import type { FormControlLabelProps } from '@mui/material/FormControlLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { useSelector } from 'react-redux';

import Checkbox from 'shared/ui/checkbox/Checkbox';
import IconButton from 'shared/ui/icon-button/IconButton';
import LoadingButton from 'shared/ui/loading-button/LoadingButton';

import type { ProgramResponse, TrialResponse } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useGetTrialsByCompanyQuery } from 'shared/api/rtkq/trials';
import { useUpdateUserAccessMutation } from 'shared/api/rtkq/users';

type Props = {
  handleClose: () => void;
  isOpen: boolean;
  name: string;
  title: string;
  userId: string;
  userPermissions: Record<string, boolean>;
  userTrials: string[];
};

type TrialCheckbox = {
  checked: boolean;
  trialTraceId: string;
  programTraceId: string;
};

type StyledFormControlLabelProps = FormControlLabelProps & {
  checked?: boolean;
};

function RadioFormControlLabel(props: StyledFormControlLabelProps) {
  const { value } = props;
  const radioGroup = useRadioGroup();
  const checked = radioGroup ? radioGroup.value === value : false;
  return <FormControlLabel {...props} checked={checked} />;
}

type TrialAccessCheckboxComponentProps = {
  checkBoxDisabled: boolean;
  handleProgramCheckboxOnChange: (
    event: ChangeEvent<HTMLInputElement>,
    programTraceId: string,
  ) => void;
  handleTrialCheckboxOnChange: (
    event: ChangeEvent<HTMLInputElement>,
    trialTraceId: string,
  ) => void;
  programs: ProgramResponse[];
  trialCheckboxes: TrialCheckbox[];
  trialsByProgram: Record<string, TrialResponse[]>;
};

function TrialAccessCheckboxComponent(
  props: TrialAccessCheckboxComponentProps,
) {
  const {
    checkBoxDisabled,
    handleProgramCheckboxOnChange,
    handleTrialCheckboxOnChange,
    programs,
    trialCheckboxes,
    trialsByProgram,
  } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        pl: 2,
        overflowY: 'scroll',
        maxHeight: '500px',
      }}
    >
      {programs.map(({ trace_id: programTraceId, name: programName }) => {
        const trialCheckboxesForProgram = trialCheckboxes.filter(
          (trial) => trial.programTraceId === programTraceId,
        );
        const programChecked = trialCheckboxesForProgram.every(
          (trial) => trial.checked,
        );
        const programIndeterminate =
          !programChecked &&
          trialCheckboxesForProgram.some((trial) => trial.checked);

        return (
          <Box key={programTraceId}>
            <Divider sx={{ py: 1, borderBottom: '1px lightgrey solid' }} />
            <FormControlLabel
              label={programName}
              sx={{ fontWeight: 'bold' }}
              control={
                <Checkbox
                  checked={programChecked}
                  disabled={checkBoxDisabled}
                  indeterminate={programIndeterminate}
                  onChange={(event) =>
                    handleProgramCheckboxOnChange(event, programTraceId)
                  }
                />
              }
            />
            {trialsByProgram[programTraceId].map((trial) => (
              <Box
                key={trial.trace_id}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 4 }}>
                  <FormControlLabel
                    label={trial.study_id}
                    control={
                      <Checkbox
                        disabled={checkBoxDisabled}
                        checked={
                          trialCheckboxes.find(
                            (trialCheckbox) =>
                              trialCheckbox.trialTraceId === trial.trace_id,
                          )?.checked ?? false
                        }
                        onChange={(event) =>
                          handleTrialCheckboxOnChange(event, trial.trace_id)
                        }
                      />
                    }
                  />
                  <Typography
                    sx={{
                      pl: 4,
                      color: (theme) =>
                        checkBoxDisabled
                          ? theme.palette.action.disabled
                          : 'inherit',
                    }}
                  >
                    {trial.indication}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    borderRadius: 1,
                    backgroundColor: (theme) => theme.palette.grey[100],
                    p: 0.5,
                  }}
                >
                  Phase {trial.phase}
                </Typography>
              </Box>
            ))}
          </Box>
        );
      })}
    </Box>
  );
}

function TrialAccessDialog(props: Props) {
  const {
    handleClose,
    isOpen,
    name,
    title,
    userId,
    userTrials,
    userPermissions,
  } = props;
  const currentCompany = useSelector(selectCompany);
  const { data: trialsByCompany } = useGetTrialsByCompanyQuery(
    currentCompany.trace_id,
  );
  const [updateUserAccess, { isLoading: updateUserAccessLoading }] =
    useUpdateUserAccessMutation();

  const allTrialPrograms = useMemo(
    () => trialsByCompany?.map((trial) => trial.program),
    [trialsByCompany],
  );
  const trialsByProgram = useMemo(
    () => groupBy(trialsByCompany ?? [], 'program.trace_id'),
    [trialsByCompany],
  );
  const programs = useMemo(
    () =>
      orderBy(uniqBy(allTrialPrograms, 'trace_id'), [
        (po) => po.name.toLowerCase(),
      ]),
    [allTrialPrograms],
  );

  const [trialCheckboxes, setTrialCheckboxes] = useState<TrialCheckbox[]>([]);
  const [checkBoxDisabled, setCheckBoxDisabled] = useState(false);
  const [specificTrials, setSpecificTrials] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      const initialTrialChecked: TrialCheckbox[] =
        trialsByCompany?.map((trial) => ({
          trialTraceId: trial.trace_id,
          programTraceId: trial.program.trace_id,
          checked:
            userTrials.includes(trial.trace_id) ||
            userTrials.includes('All Trials'),
        })) ?? [];

      setTrialCheckboxes(initialTrialChecked);
    }
  }, [isOpen, trialsByCompany, userTrials]);

  const selectAllCurrentAndFutureChecked = trialCheckboxes.every(
    (trial) => trial.checked,
  );

  const handleTrialCheckboxOnChange = (
    event: ChangeEvent<HTMLInputElement>,
    trialTraceId: string,
  ) => {
    setTrialCheckboxes((prev) =>
      prev.map((trial) =>
        trial.trialTraceId === trialTraceId
          ? { ...trial, checked: event.target.checked }
          : trial,
      ),
    );
  };

  const handleProgramCheckboxOnChange = (
    event: ChangeEvent<HTMLInputElement>,
    programTraceId: string,
  ) => {
    trialsByProgram[programTraceId].map((trial) =>
      handleTrialCheckboxOnChange(event, trial.trace_id),
    );
  };

  const handleRadioGroupChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'all_current_and_future_trials') {
      setCheckBoxDisabled(true);
      setSpecificTrials(false);
      programs.map(({ trace_id }) =>
        handleProgramCheckboxOnChange(event, trace_id),
      );
    }

    if (event.target.value === 'existing_trials') {
      setCheckBoxDisabled(false);
      setSpecificTrials(true);
    }
  };

  const handleUpdateTrialAccess = () => {
    void (async () => {
      const trials_list = trialCheckboxes
        .filter((trial) => trial.checked)
        .map((trial) => trial.trialTraceId);

      const trialsToUpdate = selectAllCurrentAndFutureChecked
        ? specificTrials
          ? trials_list
          : []
        : trials_list;

      await updateUserAccess({ trace_id: userId, trials: trialsToUpdate });
      handleClose();
    })();
  };

  return (
    <Dialog maxWidth="sm" open={isOpen} fullWidth onClose={handleClose}>
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'space-between', pb: 0 }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            color={(theme) => theme.palette.grey[800]}
            variant="body1"
          >
            {name.toUpperCase()}
          </Typography>
          <DialogContent sx={{ pl: 0, pt: 1 }}>{title}</DialogContent>
        </Box>
        <IconButton size="small" onClick={handleClose}>
          <Cancel />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ py: 3, minHeight: '250px' }}>
        <Typography variant="body1">
          <strong>
            Select which trials the selected users should have access to.
          </strong>
        </Typography>
        <RadioGroup
          name="some-radio-group"
          defaultValue={() => {
            if (
              userTrials.includes('All Trials') ||
              userPermissions.canEditCompanyUsersAndPermissions
            ) {
              setCheckBoxDisabled(true);
              return 'all_current_and_future_trials';
            }

            return 'existing_trials';
          }}
          onChange={handleRadioGroupChange}
        >
          <RadioFormControlLabel
            control={<Radio />}
            label="Give user access to all current & future trials"
            value="all_current_and_future_trials"
          />
          <RadioFormControlLabel
            control={<Radio />}
            disabled={userPermissions.canEditCompanyUsersAndPermissions}
            label="Select specific trials"
            value="existing_trials"
          />
        </RadioGroup>

        {userPermissions.canEditCompanyUsersAndPermissions ? (
          <>
            <Box sx={{ height: '20px' }} />
            <Box
              sx={{
                background: (theme) => theme.palette.grey[300],
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                gap: '15px',
                px: '20px',
                py: '10px',
              }}
            >
              <Box>
                <InfoOutlinedIcon color="secondary" fontSize="small" />
              </Box>
              <Box sx={{ flexDirection: 'column' }}>
                <Typography fontWeight="bold">
                  This user has the permission to manage other users and
                  permissions, so they have access to all companies and trials.
                </Typography>
                <Typography variant="body1">
                  If this user should not have access to all companies and
                  trials, you must revoke their permission to manage other
                  users.
                </Typography>
              </Box>
            </Box>
          </>
        ) : (
          <TrialAccessCheckboxComponent
            checkBoxDisabled={checkBoxDisabled}
            handleProgramCheckboxOnChange={handleProgramCheckboxOnChange}
            handleTrialCheckboxOnChange={handleTrialCheckboxOnChange}
            programs={programs}
            trialCheckboxes={trialCheckboxes}
            trialsByProgram={trialsByProgram}
          />
        )}
      </DialogContent>
      <DialogActions>
        {!userPermissions.canEditCompanyUsersAndPermissions && (
          <LoadingButton
            disabled={trialCheckboxes.every((tcb) => !tcb.checked)}
            loading={updateUserAccessLoading}
            testId="test_update_trial_access"
            variant="contained"
            onClick={handleUpdateTrialAccess}
          >
            Update Trial Access
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default memo(TrialAccessDialog);
