import AttachFileIcon from '@mui/icons-material/AttachFile';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { formatDistance } from 'date-fns/formatDistance';

import Chip from 'shared/ui/chip/Chip';

import type { CommentResponse } from 'shared/lib/types';

type Props = Pick<CommentResponse, 'created_at' | 'files' | 'text' | 'user'>;

function basename(path: string, sep: string) {
  return path.substr(path.lastIndexOf(sep) + 1);
}

function CommentListItem(props: Props) {
  const { user, text, files, created_at } = props;
  return (
    <Box sx={{ px: 3, py: 2 }}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Avatar sx={{ width: 30, height: 30 }}>
          {user?.first_name?.[0] ?? '?'}
        </Avatar>
        <Typography
          ml={1}
          variant="subtitle2"
        >{`${user?.first_name} ${user?.last_name}`}</Typography>
        {!!created_at && (
          <Typography color="text.secondary" ml={0.5} mt={-0.8}>
            {formatDistance(new Date(), new Date(created_at))}
          </Typography>
        )}
      </Stack>
      <Typography mt={2}>{text}</Typography>
      <Stack
        columnGap={2}
        flexDirection="row"
        flexWrap="wrap"
        mt={1}
        rowGap={1}
      >
        {files?.map((file) => (
          <Chip
            key={file.name}
            icon={<AttachFileIcon />}
            label={basename(file.name, '/')}
            size="small"
          />
        ))}
      </Stack>
    </Box>
  );
}
export default CommentListItem;
