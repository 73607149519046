import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';

import IconButton, {
  iconButtonClasses,
} from 'shared/ui/icon-button/IconButton';

import { type LockGrid, LockGridStatus } from 'shared/lib/types';
import { selectUserCanLockGrids } from 'shared/state/slices/userSlice';

import withFeatureFlagRequired from 'shared/api/hocs/withFeatureFlagRequired';
import {
  useGetLockGridWithFiltersQuery,
  useUpsertLockGridMutation,
} from 'shared/api/rtkq/lockgrids';

import useLockGridQueryParams from './useLockGridQueryParams';

type Props = {
  grid: LockGrid;
};

function LockGridButton(props: Props) {
  const { grid } = props;
  const params = useLockGridQueryParams(grid);
  const userCanLockGrids = useSelector(selectUserCanLockGrids);
  const { currentData } = useGetLockGridWithFiltersQuery(params);
  const [upsertLockGrid] = useUpsertLockGridMutation();

  function onClick() {
    void upsertLockGrid({
      ...params,
      status:
        currentData?.[0]?.status === LockGridStatus.LOCK_GRID
          ? LockGridStatus.UNLOCK_GRID
          : LockGridStatus.LOCK_GRID,
    });
  }

  return (
    <Tooltip
      disableFocusListener={userCanLockGrids}
      disableHoverListener={userCanLockGrids}
      disableTouchListener={userCanLockGrids}
      placement="top"
      title="You do not have permission to lock or unlock grids"
      arrow
    >
      <IconButton
        data-testid={`${grid}_LOCK`}
        disabled={!userCanLockGrids}
        sx={{
          ml: 1,
          [`&.${iconButtonClasses.root}`]: {
            backgroundColor: ({ palette }) => palette.primary.light,
          },
        }}
        onClick={onClick}
      >
        {currentData?.[0]?.status === LockGridStatus.LOCK_GRID ? (
          <LockOutlinedIcon color="primary" />
        ) : (
          <LockOpenOutlinedIcon color="primary" />
        )}
      </IconButton>
    </Tooltip>
  );
}

export default withFeatureFlagRequired(LockGridButton, 'lock_grid');
