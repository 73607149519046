import { useSelector } from 'react-redux';

import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import type { UserPermissions } from 'shared/lib/types';
import {
  selectCurrentUserPermissions,
  selectUserCanEdit,
} from 'shared/state/slices/userSlice';

type Props = {
  permissions: Array<keyof UserPermissions>;
  separator?: 'AND' | 'OR';
};

export default function useHasPermission(props: Props) {
  const { permissions, separator = 'AND' } = props;
  const usersAndPermissionsFeatureFlag = useFeatureFlag(
    'users_and_permissions',
  );
  const currentUserPermissions = useSelector(selectCurrentUserPermissions);
  const canEditData = useSelector(selectUserCanEdit);

  const hasPermission =
    separator === 'AND'
      ? permissions.every(
          (permission: string) =>
            currentUserPermissions[permission as keyof UserPermissions],
        )
      : permissions.some(
          (permission: string) =>
            currentUserPermissions[permission as keyof UserPermissions],
        );

  return usersAndPermissionsFeatureFlag ? hasPermission : canEditData;
}
