import { useMemo, useState } from 'react';

import Box from '@mui/material/Box';

import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import type { CurrencyViewMode } from 'shared/lib/currency-toggle-group/CurrencyToggleGroup';
import CurrencyToggleGroup from 'shared/lib/currency-toggle-group/CurrencyToggleGroup';
import HasPermission from 'shared/lib/permissions/HasPermission';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

import ClinicalExpenseSummaryGridHeader from './components/ClinicalExpenseSummaryGridHeader';
import ClinicalExpenseSummaryGrid from './grids/ClinicalExpenseSummaryGrid';

function SummaryPage() {
  const [currencyViewMode, setCurrencyViewMode] =
    useState<CurrencyViewMode>('trial');
  const gridSx = useMemo(() => ({ height: '100%' }), []);
  const userDisplayOptions = useMemo(
    () => ({
      currencyViewMode,
    }),
    [currencyViewMode],
  );

  return (
    <PageContentLoadingContainer>
      <DashboardCard
        sx={{ display: 'flex', height: '100%', flexFlow: 'column' }}
        title="Clinical Expense Summary"
        titleAction={
          <Box alignItems="center" display="flex" gap={1}>
            <CurrencyToggleGroup
              setValue={setCurrencyViewMode}
              testId="ClinicalExpenseSummaryToggle"
              value={currencyViewMode}
            />
            <HasPermission permissions={['canEditTrialInfo']}>
              <ClinicalExpenseSummaryGridHeader />
            </HasPermission>
          </Box>
        }
      >
        <FlexGridContainer>
          <ClinicalExpenseSummaryGrid
            sx={gridSx}
            userDisplayOptions={userDisplayOptions}
          />
        </FlexGridContainer>
      </DashboardCard>
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(SummaryPage);
