import { useMemo } from 'react';

import {
  getGenericCellConfig,
  getNumberCellConfig,
} from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import useHasPermission from 'shared/lib/permissions/useHasPermission';
import type { CurrencyCode } from 'shared/lib/types';

export function processFxRateColumnDefs(
  isOpenPeriod: boolean,
  canEditCompanyLevelInfo: boolean,
  inputCurrencies: CurrencyCode[],
): CondorColDef[] {
  return [
    {
      headerName: 'Period',
      field: 'end_date',
      pinned: 'left',
      cellStyle: { fontWeight: 'bold' },
      valueFormatter: 'humanizePeriodDate',
    },
    ...inputCurrencies.map((currency) => ({
      headerName: currency,
      field: currency,
      ...(isOpenPeriod && canEditCompanyLevelInfo
        ? {
            ...getNumberCellConfig(),
            editable: true,
          }
        : { ...getGenericCellConfig() }),
    })),
  ];
}

export default function useFxRateColumnDefs(
  isOpenPeriod: boolean,
  inputCurrencies: CurrencyCode[],
): CondorColDef[] {
  const canEditCompanyLevelInfo = useHasPermission({
    permissions: ['canEditCompanyLevelInfo'],
  });
  return useMemo(
    () =>
      processFxRateColumnDefs(
        isOpenPeriod,
        canEditCompanyLevelInfo,
        inputCurrencies,
      ),
    [isOpenPeriod, canEditCompanyLevelInfo, inputCurrencies],
  );
}
