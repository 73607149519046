import { type ReactElement, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

import Button from 'shared/ui/button/Button';

import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';
import HasPermission from 'shared/lib/permissions/HasPermission';
import withReadOnlyPermissionWrapper from 'shared/lib/read-only-permission/withReadOnlyPermissionWrapper';
import { selectCompany } from 'shared/state/slices/companySlice';

import AddEditGlAccountDialog from './AddEditGlAccountDialog';

function GlAccountsHeader(): ReactElement {
  const currentCompany = useSelector(selectCompany);

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        mb: 2,
        flexGrow: 0,
        flexShrink: 0,
      }}
    >
      <HasPermission permissions={['canEditCompanyLevelInfo']}>
        <Button
          startIcon={<AddIcon />}
          testId="add_gl"
          variant="contained"
          onClick={() => setIsOpenDialog(true)}
        >
          Add G/L Account
        </Button>
      </HasPermission>
      {isOpenDialog && (
        <AddEditGlAccountDialog
          companyTraceId={currentCompany.trace_id}
          onClose={() => setIsOpenDialog(false)}
        />
      )}
    </Box>
  );
}

export default withReadOnlyPermissionWrapper(
  withPeriodSpecificGenericWrapper(GlAccountsHeader),
);
