import constructApi from './constructApi';

const PATH = 'condorfiles';

const apiEndpointFactory = constructApi(PATH).withTags('CONDOR_FILES');
const api = apiEndpointFactory.inject(() => ({
  deleteCondorFile: apiEndpointFactory.delete(),
}));

export const { useDeleteCondorFileMutation } = api;
