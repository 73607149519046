import { useState } from 'react';

import type { ICellRendererParams } from '@ag-grid-community/core';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// eslint-disable-next-line import/no-restricted-paths -- ag-grid has to be shared for all of this to work
import ContractVersionWizard from 'accruals/wizards/contract-version-wizard/ContractVersionWizard';
import HasPermission from 'shared/lib/permissions/HasPermission';

function AgGridOccContractContainerAddNewCellRenderer(
  params: ICellRendererParams,
) {
  const { data, api: gridApi } = params;
  const [openContractVersionWizard, setOpenContractVersionWizard] =
    useState(false);

  return (
    <>
      {openContractVersionWizard && (
        <ContractVersionWizard
          contractContainerId={data.contract_container_trace_id}
          onClose={() => {
            setOpenContractVersionWizard(false);
            gridApi
              .getSelectedNodes()
              .find((node) => node.data.create_new && node.isSelected())
              ?.setSelected(false);
          }}
        />
      )}
      <HasPermission permissions={['canEditTrialInfo']}>
        <Typography
          variant="caption"
          sx={{
            display: 'flex',
            mt: 1.25,
            ml: 1.25,
            cursor: 'pointer',
            color: (theme) => theme.palette.grey[700],
          }}
          onClick={() => setOpenContractVersionWizard(true)}
        >
          <ControlPointIcon sx={{ fontSize: 19 }} />{' '}
          <Box sx={{ alignSelf: 'center', marginLeft: 1 }}>New Version</Box>
        </Typography>
      </HasPermission>
    </>
  );
}

export default AgGridOccContractContainerAddNewCellRenderer;
