import * as Sentry from '@sentry/react';
import { Navigate } from 'react-router';
import { Route, Routes } from 'react-router-dom';

import AccrualsCleanLayout from 'accruals/containers/accruals-clean-layout/AccrualsCleanLayout';
import AccrualsLayout from 'accruals/containers/accruals-layout/AccrualsLayout';
import AnalyticsPage from 'accruals/pages/analytics//AnalyticsPage';
import CroContractContainerPage from 'accruals/pages/clinical-expenses/cro/CroContractContainerPage';
import CroContractVersionPage from 'accruals/pages/clinical-expenses/cro/CroContractVersionPage';
import OccDetailTab from 'accruals/pages/clinical-expenses/occ/OccDetailTab';
import OccExpensesTab from 'accruals/pages/clinical-expenses/occ/OccExpensesTab';
import OccOverviewTab from 'accruals/pages/clinical-expenses/occ/OccOverviewTab';
import OccReconTab from 'accruals/pages/clinical-expenses/occ/OccReconTab';
import SummaryPage from 'accruals/pages/clinical-expenses/summary/SummaryPage';
import ClosedPeriodCompanyFilesPage from 'accruals/pages/company-files/ClosedPeriodCompanyFilesPage';
import FinancialClosePage from 'accruals/pages/financial-close/FinancialClosePage';
import ClosedPeriodFxRatePage from 'accruals/pages/fx-rate/ClosedPeriodFxRatePage';
import ClosedPeriodGlAccountsPage from 'accruals/pages/gl-accounts/ClosedPeriodGlAccountsPage';
import ClosedPeriodInvoiceListingPage from 'accruals/pages/invoice/ClosedPeriodInvoiceListingPage';
import JournalEntryPage from 'accruals/pages/journal-entry/JournalEntryPage';
import ClosedPeriodPurchaseOrdersPage from 'accruals/pages/purchase-orders/ClosedPeriodPurchaseOrdersPage';
import PatientContainerPage from 'accruals/pages/trial-activity/patient-container/PatientContainerPage';
import SiteLabCostMatrixPage from 'accruals/pages/trial-activity/site-lab-cost-matrix/SiteLabCostMatrixPage';
import TrialFilesPage from 'accruals/pages/trial-files/TrialFilesPage';
import TrialDashboardPage from 'accruals/pages/trial/TrialDashboardPage';
import ClosedPeriodUserAccessPage from 'accruals/pages/user-permissions/ClosedPeriodUserAccessPage';
import ClosedPeriodVarianceThresholdPage from 'accruals/pages/variance-threshold/ClosedPeriodVarianceThresholdPage';
import CompanyLayout from 'company/containers/company-layout/CompanyLayout';
import SettingsLayout from 'company/containers/settings-layout/SettingsLayout';
import AuditLogPage from 'company/pages/audit-log/AuditLogPage';
import OpenPeriodCompanyFilesPage from 'company/pages/company-files/OpenPeriodCompanyFilesPage';
import CompanyOverviewPage from 'company/pages/company-overview/CompanyOverviewPage';
import CompanySettingsPage from 'company/pages/company-settings/CompanySettingsPage';
import ControlListingPage from 'company/pages/control-listing/ControlListingPage';
import OpenPeriodFxRatePage from 'company/pages/fx-rate/OpenPeriodFxRatePage';
import OpenPeriodGlAccountsPage from 'company/pages/gl-accounts/OpenPeriodGlAccountsPage';
import OpenPeriodInvoiceListingPage from 'company/pages/invoice/OpenPeriodInvoiceListingPage';
import OpenPeriodPurchaseOrdersPage from 'company/pages/purchase-orders/OpenPeriodPurchaseOrdersPage';
import OpenPeriodUserAccessPage from 'company/pages/user-permissions/OpenPeriodUserAccessPage';
import OpenPeriodVarianceThresholdPage from 'company/pages/variance-threshold/OpenPeriodVarianceThresholdPage';
import ForecastingCleanLayout from 'forecasting/containers/forecasting-clean-layout/ForecastingCleanLayout';
import ForecastingLayout from 'forecasting/containers/forecasting-layout/ForecastingLayout';
import ForecastingPage from 'forecasting/pages/forecasting/ForecastingPage';
import * as routes from 'routes';
import ComponentSandbox from 'sandbox/component-sandbox/ComponentSandbox';
import DemoViewer from 'sandbox/demo-viewer/DemoViewer';
import SandboxLayout from 'shared/containers/sandbox-layout/SandboxLayout';

const RoutesWithSentry = Sentry.withSentryReactRouterV6Routing(Routes);

function getCompanyPages(isOpen: boolean) {
  const path = isOpen ? routes.getCompany() : routes.getCompanyPeriodClosed();

  return [
    {
      path: routes.getCompanyFiles(path),
      element: isOpen ? (
        <OpenPeriodCompanyFilesPage />
      ) : (
        <ClosedPeriodCompanyFilesPage />
      ),
    },
    {
      path: routes.getInvoices(path),
      element: isOpen ? (
        <OpenPeriodInvoiceListingPage />
      ) : (
        <ClosedPeriodInvoiceListingPage />
      ),
    },
    {
      path: routes.getPurchaseOrders(path),
      element: isOpen ? (
        <OpenPeriodPurchaseOrdersPage />
      ) : (
        <ClosedPeriodPurchaseOrdersPage />
      ),
    },
    {
      path: routes.getFxRates(path),
      element: isOpen ? <OpenPeriodFxRatePage /> : <ClosedPeriodFxRatePage />,
    },
    {
      path: routes.getFxRatesTab(path),
      element: isOpen ? <OpenPeriodFxRatePage /> : <ClosedPeriodFxRatePage />,
    },
    {
      path: routes.getGlAccounts(path),
      element: isOpen ? (
        <OpenPeriodGlAccountsPage />
      ) : (
        <ClosedPeriodGlAccountsPage />
      ),
    },
    {
      path: routes.getVariance(path),
      element: isOpen ? (
        <OpenPeriodVarianceThresholdPage />
      ) : (
        <ClosedPeriodVarianceThresholdPage />
      ),
    },
    {
      path: routes.getUsersAndPermissions(path),
      element: isOpen ? (
        <OpenPeriodUserAccessPage />
      ) : (
        <ClosedPeriodUserAccessPage />
      ),
    },
  ];
}

function AppRoutes() {
  return (
    <RoutesWithSentry>
      <Route element={<AccrualsCleanLayout />}>
        <Route element={<AccrualsLayout />}>
          <Route
            element={<Navigate to="./trial-dashboard" />}
            path={routes.getHomePage()}
          />
          <Route
            element={<Navigate to="./accruals" />}
            path={routes.getAnalytics()}
          />
          <Route element={<AnalyticsPage />} path={routes.getAnalyticsTab()} />
          <Route
            element={<PatientContainerPage />}
            path={routes.getPatientNavigation()}
          />
          <Route
            element={<PatientContainerPage />}
            path={routes.getPatientJourneyByCohortNavigation()}
          />
          <Route
            element={<Navigate to="./checklist" />}
            path={routes.getFinancialClose()}
          />
          <Route
            element={<FinancialClosePage />}
            path={routes.getFinancialCloseTab()}
          />
          <Route element={<OccOverviewTab />} path={routes.getOcc()} />
          <Route
            element={<OccDetailTab />}
            path={routes.getOccByContractVersion()}
          />
          <Route element={<OccExpensesTab />} path={routes.getOccExpenses()} />
          <Route element={<OccReconTab />} path={routes.getOccRecon()} />
          <Route
            element={<JournalEntryPage />}
            path={routes.getJournalEntry()}
          />
          <Route
            element={<TrialDashboardPage />}
            path={routes.getTrialDashboard()}
          />
          <Route
            element={<SummaryPage />}
            path={routes.getClinicalExpenses()}
          />
          <Route
            element={<CroContractVersionPage />}
            path={routes.getClinicalExpensesByContractVersion()}
          />
          <Route
            element={<Navigate to="./overview" />}
            path={routes.getClinicalExpensesByContractContainer()}
          />
          <Route
            element={<CroContractContainerPage />}
            path={routes.getClinicalExpensesTabByContractContainer()}
          />
          <Route
            element={<CroContractContainerPage />}
            path={routes.getClinicalExpensesSubTabByContractContainer()}
          />
          <Route
            element={<Navigate to="./sites" />}
            path={routes.getSiteLabCostMatrix()}
          />
          <Route
            element={<SiteLabCostMatrixPage />}
            path={routes.getSiteLabCostMatrixTab()}
          />
          <Route
            element={<Navigate to="./period" />}
            path={routes.getTrialFiles()}
          />
          <Route
            element={<TrialFilesPage />}
            path={routes.getTrialFilesNavigation()}
          />
          {getCompanyPages(false).map(({ path, element }) => (
            <Route key={path} element={element} path={path} />
          ))}
        </Route>
      </Route>

      <Route element={<ForecastingCleanLayout />}>
        <Route element={<ForecastingLayout />}>
          <Route element={<ForecastingPage />} path={routes.getForecast()} />
          <Route
            element={<Navigate to="./summary" />}
            path={routes.getSpecificForecast()}
          />
          <Route element={<ForecastingPage />} path={routes.getForecastTab()} />
        </Route>
      </Route>

      <Route element={<CompanyLayout />}>
        <Route element={<AuditLogPage />} path={routes.getAuditLog()} />
        <Route
          element={<Navigate to="./dashboard" />}
          path={routes.getCompany()}
        />
        <Route
          element={<CompanyOverviewPage />}
          path={routes.getCompanyDashboard()}
        />
        {getCompanyPages(true).map(({ path, element }) => (
          <Route key={path} element={element} path={path} />
        ))}
      </Route>

      <Route element={<SettingsLayout />}>
        <Route
          element={<CompanySettingsPage />}
          path={routes.getCompanySettings()}
        />
        <Route
          element={<Navigate to="./company" />}
          path={routes.getSettings()}
        />
        <Route
          element={<ControlListingPage />}
          path={routes.getControlListing()}
        />
        <Route
          element={<OpenPeriodUserAccessPage />}
          path={routes.getUsersAndPermissions()}
        />
      </Route>

      <Route element={<SandboxLayout />}>
        <Route
          element={<Navigate to="./alert" />}
          path={routes.getDevComponents()}
        />
        <Route element={<ComponentSandbox />} path={routes.getDevComponent()} />
        <Route
          element={<Navigate to="./PercentContracted" />}
          path={routes.getDevDemos()}
        />
        <Route element={<DemoViewer />} path={routes.getDevDemo()} />
      </Route>
    </RoutesWithSentry>
  );
}

export default AppRoutes;
