import type { ReactNode } from 'react';

import Box from '@mui/material/Box';

import UserPermissionBanner from 'shared/lib/user-permission-banner/UserPermissionBanner';

type Props = {
  children: ReactNode;
};

function PageContentContainer(props: Props) {
  const { children } = props;

  return (
    <Box
      id="page-content"
      sx={{
        flex: 1,
        minHeight: 0,
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      <UserPermissionBanner />
      {children}
    </Box>
  );
}

export default PageContentContainer;
