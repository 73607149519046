import { useState } from 'react';

import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';

import Alert from 'shared/ui/alert/Alert';

import ContractVersionBackButton from 'accruals/pages/clinical-expenses/shared/contract-version/ContractVersionBackButton';
import ContractVersionWizard from 'accruals/wizards/contract-version-wizard/ContractVersionWizard';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import HasPermission from 'shared/lib/permissions/HasPermission';
import type { ContractStatusOptions } from 'shared/lib/types';
import {
  backendValueFromStatus,
  statusFromBackendValue,
} from 'shared/lib/types';

import { useUpdateContractStatusMutation } from 'shared/api/rtkq/contractstatuses';

import ConfirmToChangeStatus from '../shared/ConfirmToChangeStatus';
import ContractAssumptionsSection from '../shared/ContractAssumptionsSection';
import ContractVersionDetailAddVersion from '../shared/ContractVersionDetailAddVersion';
import ContractRecordSection from '../shared/contract-record/ContractRecordSection';
import ContractVersionStatusSection from '../shared/contract-version/ContractVersionStatusSection';
import useContractAndPeriodWithVersions from '../shared/hooks/useContractAndPeriodWithVersions';
import OccContractBudgetSection from './OccContractBudgetSection';
import OccContractFilesSection from './OccContractFilesSection';

function OccContractVersionDetail() {
  const { contractContainerTraceId, contractVersion } =
    useContractAndPeriodWithVersions();

  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openContractVersionWizard, setOpenContractVersionWizard] =
    useState<boolean>(false);

  const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false);

  const [updateContractStatus] = useUpdateContractStatusMutation();
  const isContractStatusV2Enabled = useFeatureFlag('contract_status_v2');
  const [statusToChangeTo, setStatusToChangeTo] =
    useState<ContractStatusOptions>();

  function onRejectChanges() {
    setOpenConfirm(false);
  }

  function onConfirmChanges() {
    setOpenConfirm(false);
    if (!isContractStatusV2Enabled) {
      void (async () => {
        await updateContractStatus({
          trace_id: contractVersion?.status_for_period ?? '',
          status: backendValueFromStatus(statusToChangeTo),
        });
      })();
    }

    setStatusToChangeTo(undefined);
    setOpenContractVersionWizard(true);
    setShowSuccessMsg(true);
  }

  function handleVersionSelection() {
    setOpenContractVersionWizard(true);
  }

  return (
    <>
      {contractContainerTraceId !== undefined && openContractVersionWizard && (
        <ContractVersionWizard
          contractContainerId={contractContainerTraceId}
          contractVersionTraceId={undefined}
          onClose={() => setOpenContractVersionWizard(false)}
        />
      )}
      <Stack direction="row" justifyContent="space-between">
        <ContractVersionBackButton isOcc />
        <HasPermission permissions={['canEditTrialInfo']}>
          <ContractVersionDetailAddVersion
            onHandleVersionSelection={handleVersionSelection}
          />
        </HasPermission>
      </Stack>
      <Grid mt={2} spacing={3} container>
        <Grid xs={8} item>
          <ContractAssumptionsSection />
        </Grid>
        <Grid xs={4} item>
          <ContractVersionStatusSection />
          <ContractRecordSection />
          <OccContractFilesSection />
        </Grid>
        <Grid xs={12} item>
          <OccContractBudgetSection />
        </Grid>
      </Grid>
      {openConfirm && contractContainerTraceId && contractVersion && (
        <ConfirmToChangeStatus
          contractContainerTraceId={contractContainerTraceId}
          contractVersionTraceId={contractVersion.trace_id}
          existingAipEffectiveDate={contractVersion.aip_effective_date}
          existingEffectiveDate={contractVersion.effective_date}
          existingVoidedDate={contractVersion.voided_date}
          newStatus={statusToChangeTo}
          currentStatus={
            contractVersion.status_for_period
              ? statusFromBackendValue(contractVersion.status_for_period)
              : undefined
          }
          onClose={onRejectChanges}
          onConfirm={onConfirmChanges}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={4000}
        open={showSuccessMsg}
        onClose={() => setShowSuccessMsg(false)}
      >
        <Alert severity="success">Contract status changed.</Alert>
      </Snackbar>
    </>
  );
}

export default OccContractVersionDetail;
