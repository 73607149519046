/* eslint-disable @typescript-eslint/no-restricted-imports -- Overridden MUI component is only allowed in this file that overrides it */
import MuiIconButton, { iconButtonClasses } from '@mui/material/IconButton';
import type { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton/IconButton';
/* eslint-enable @typescript-eslint/no-restricted-imports */

function IconButton(props: IconButtonProps) {
  return <MuiIconButton {...props} />;
}

type Variant = 'primary' | 'secondary' | 'tertiary';

type IconButtonProps = MuiIconButtonProps & {
  variant?: Variant;
  component?: React.ElementType;
};

export { iconButtonClasses, type IconButtonProps };
export default IconButton;
