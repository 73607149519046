import type {
  CellClassParams,
  ProcessCellForExportParams,
  RowClassParams,
} from '@ag-grid-community/core';
import { format } from 'date-fns/format';
import { isValid } from 'date-fns/isValid';
import { enUS } from 'date-fns/locale/en-US';
import { parse } from 'date-fns/parse';

import DateFormatCondition from 'shared/lib/snapshot-validator/DateFormatCondition';

const isDataFieldZero = (params: CellClassParams) =>
  params.data?.[params.colDef.field ?? 'not_a_field'] === 0;

const isDataFieldEmpty = (params: CellClassParams) =>
  params.data?.[params.colDef.field ?? 'not_a_field'] == null;

export const EM_DASH_PLACEHOLDER = '—';

export const boldCell = (params: CellClassParams) =>
  isDataFieldEmpty(params) || isDataFieldZero(params)
    ? null
    : { fontWeight: 'bold' };

export const highlightGroup = (params: CellClassParams | RowClassParams) =>
  params.node.group ? { fontWeight: 'bold' } : undefined;

export const highlightCell = (params: CellClassParams) =>
  isDataFieldEmpty(params)
    ? { backgroundColor: 'rgba(97,61,240, .2)' } // TODO: Handle dark mode - possibly by adding themeMode to the context.
    : { backgroundColor: 'auto' };

export const valueIsMultipleStringArray = (value: unknown): value is string[] =>
  Array.isArray(value) &&
  value.every((element) => typeof element === 'string') &&
  value.length > 1;

export const valueIsSingleOrMultipleTrialArray = (
  value: unknown,
): value is string[] =>
  Array.isArray(value) && value.length >= 1 && value[0] !== 'All Trials';

export const journalEntryGLAccountColumn = (
  params: CellClassParams | RowClassParams,
) =>
  (params.data?.is_expense_account as boolean)
    ? { fontWeight: 'bold' }
    : (params.data?.gl_account_exists as boolean)
      ? { paddingLeft: '30px' }
      : { color: 'red', paddingLeft: '30px' };

export const patientJourneyExpectedVisit = (params: CellClassParams) => {
  const visitField = params.colDef.colId;
  const expectedVisitField = `expected_${visitField}`;

  if (
    params.data !== undefined &&
    expectedVisitField in params.data &&
    params.data[expectedVisitField] !== undefined
  ) {
    return { fontStyle: 'italic', color: '#613df0' }; // TODO: Handle dark mode - possibly by adding themeMode to the context.
  }

  return { fontStyle: 'normal' };
};

export const filterDateValueFromClipboard = (
  params: ProcessCellForExportParams,
) => {
  const { value, column } = params;
  const { field } = column.getColDef();

  const fieldNames = [
    'startDate',
    'endDate',
    'end_date',
    'start_date',
    'initiated_date',
    'closed_date',
    'aipStartDate',
    'aipEndDate',
    'execution_date',
    'recruited_date',
    'invoice_paid_date',
    'invoice_date',
  ];

  if (typeof field !== 'string' || !fieldNames.includes(field)) {
    return value;
  }

  const dateFormat = DateFormatCondition.getValidFormat(value);
  if (!dateFormat) {
    return '';
  }

  const parsed = parse(value, dateFormat, new Date(), { locale: enUS });
  if (isValid(parsed)) {
    return format(parsed, 'yyyy-MM-dd');
  }

  return '';
};
