import useIsReadOnlyPermission from 'shared/lib/read-only-permission/useIsReadOnlyPermission';

import type { CondorColDef } from '../types';

export default function useGridColDefs<T>(
  colDefsHook: (...rest: any[]) => Array<CondorColDef<T>>,
  params?: unknown[],
  isGridLocked?: boolean, // TODO: Check useLockedGridStatus inside here so we can pass a LockGrid value instead of a boolean
): Array<CondorColDef<T>> {
  const isReadOnlyPermission = useIsReadOnlyPermission();
  return colDefsHook.apply(null, [
    !isReadOnlyPermission && !isGridLocked,
    ...(params ?? []),
  ]);
}
