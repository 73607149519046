import { useState } from 'react';

import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import { useParams } from 'react-router-dom';

import Alert from 'shared/ui/alert/Alert';

import ContractVersionWizard from 'accruals/wizards/contract-version-wizard/ContractVersionWizard';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import type { BackendContractStatus } from 'shared/lib/types';
import {
  ContractStatusOptions,
  backendValueFromStatus,
  statusFromBackendValue,
} from 'shared/lib/types';
import HasPermission from 'shared/lib/permissions/HasPermission';

import { useUpdateContractStatusMutation } from 'shared/api/rtkq/contractstatuses';

import AmendmentInProgressMissing from '../shared/AmendmentInProgressMissing';
import ConfirmToChangeStatus from '../shared/ConfirmToChangeStatus';
import ContractAssumptionsSection from '../shared/ContractAssumptionsSection';
import ContractVersionDetailAddVersion from '../shared/ContractVersionDetailAddVersion';
import ContractRecordSection from '../shared/contract-record/ContractRecordSection';
import ContractVersionBackButton from '../shared/contract-version/ContractVersionBackButton';
import ContractVersionStatusSection from '../shared/contract-version/ContractVersionStatusSection';
import useContractAndPeriodWithVersions from '../shared/hooks/useContractAndPeriodWithVersions';
import CroContractBudgetSection from './CroContractBudgetSection';
import CroContractFilesSection from './CroContractFilesSection';

type Props = { showBackButton?: boolean };

function CroContractVersionDetail(props: Props) {
  const { activeTab } = useParams();
  const {
    contractContainerTraceId,
    contractVersionTraceId,
    contractVersionPeriodMenuItemTraceId,
    contractVersion,
  } = useContractAndPeriodWithVersions();
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openContractVersionWizard, setOpenContractVersionWizard] =
    useState<boolean>(false);

  const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false);
  const [updateContractStatus] = useUpdateContractStatusMutation();
  const isContractStatusV2Enabled = useFeatureFlag('contract_status_v2');

  const [statusToChangeTo, setStatusToChangeTo] =
    useState<ContractStatusOptions>();
  const [contractStatusOfCurrentTab, setContractStatusOfCurrentTab] =
    useState<BackendContractStatus>(
      activeTab === 'amendment-in-progress' ? 'AIP' : 'CURRENT',
    );
  const { showBackButton = false } = props;

  function onRejectChanges() {
    setOpenConfirm(false);
  }

  function onConfirmChanges() {
    setOpenConfirm(false);
    if (!isContractStatusV2Enabled) {
      void (async () => {
        await updateContractStatus({
          trace_id: contractVersion?.status_trace_id_for_period ?? '',
          status: backendValueFromStatus(statusToChangeTo),
        });
      })();
    }
    setStatusToChangeTo(undefined);
    setOpenContractVersionWizard(true);
    setShowSuccessMsg(true);

    activeTab === 'amendment-in-progress'
      ? setContractStatusOfCurrentTab('AIP')
      : setContractStatusOfCurrentTab('CURRENT');
  }

  function handleCreateAIP() {
    setOpenContractVersionWizard(true);
  }

  function handleVersionSelection() {
    if (
      contractContainerTraceId !== undefined &&
      activeTab === 'amendment-in-progress'
    ) {
      setStatusToChangeTo(ContractStatusOptions.VOIDED);
      setOpenConfirm(true);
    } else if (
      contractVersionTraceId !== undefined &&
      activeTab === 'current-contract'
    ) {
      setStatusToChangeTo(ContractStatusOptions.SUPERSEDED);
      setOpenConfirm(true);
    } else {
      setOpenContractVersionWizard(true);
    }
  }

  return (
    <>
      {contractContainerTraceId !== undefined && openContractVersionWizard && (
        <ContractVersionWizard
          contractContainerId={contractContainerTraceId}
          contractVersionTraceId={undefined}
          contractStatus={
            contractStatusOfCurrentTab === 'AIP'
              ? contractStatusOfCurrentTab
              : undefined
          }
          contractVersion={
            contractStatusOfCurrentTab === 'AIP' ? 'AMENDMENT' : undefined
          }
          onClose={() => setOpenContractVersionWizard(false)}
        />
      )}
      {activeTab === 'amendment-in-progress' &&
      contractVersionTraceId === undefined &&
      contractVersionPeriodMenuItemTraceId === undefined ? (
        <AmendmentInProgressMissing onAIPCreated={handleCreateAIP} />
      ) : (
        <>
          <Stack
            direction="row"
            justifyContent={showBackButton ? 'space-between' : 'flex-end'}
          >
            {showBackButton && <ContractVersionBackButton />}
            <HasPermission permissions={['canEditTrialInfo']}>
              <ContractVersionDetailAddVersion
                onHandleVersionSelection={handleVersionSelection}
              />
            </HasPermission>
          </Stack>
          <Grid mt={2} spacing={3} container>
            <Grid xs={8} item>
              <ContractAssumptionsSection />
            </Grid>
            <Grid xs={4} item>
              <ContractVersionStatusSection />
              <ContractRecordSection />
              <CroContractFilesSection />
            </Grid>
            <Grid xs={12} item>
              <CroContractBudgetSection />
            </Grid>
          </Grid>
          {openConfirm && contractContainerTraceId && contractVersion && (
            <ConfirmToChangeStatus
              contractContainerTraceId={contractContainerTraceId}
              contractVersionTraceId={contractVersion.trace_id}
              existingAipEffectiveDate={contractVersion.aip_effective_date}
              existingEffectiveDate={contractVersion.effective_date}
              existingVoidedDate={contractVersion.voided_date}
              newStatus={statusToChangeTo}
              currentStatus={
                contractVersion.status_for_period
                  ? statusFromBackendValue(contractVersion.status_for_period)
                  : undefined
              }
              onClose={onRejectChanges}
              onConfirm={onConfirmChanges}
            />
          )}
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            autoHideDuration={4000}
            open={showSuccessMsg}
            onClose={() => setShowSuccessMsg(false)}
          >
            <Alert severity="success">Contract status changed.</Alert>
          </Snackbar>
        </>
      )}
    </>
  );
}

export default CroContractVersionDetail;
