import { type ReactElement, useCallback, useState } from 'react';

import type { IsFullWidthRowParams } from '@ag-grid-community/core';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/material/styles';
import { skipToken } from '@reduxjs/toolkit/query';
import { useSelector } from 'react-redux';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';
import Modal from 'shared/components/modal/Modal';
import Alert from 'shared/ui/alert/Alert';

import type { StatusChangeConfirmationModalData } from 'accruals/pages/clinical-expenses/cro/grids/CroVersionHistoryGrid';
import useOccVersionHistoryColDefs from 'accruals/pages/clinical-expenses/occ/hooks/useOccVersionHistoryColDefs';
import ConfirmToChangeStatus from 'accruals/pages/clinical-expenses/shared/ConfirmToChangeStatus';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import type {
  BackendContractStatus,
  VersionHistoryRow,
} from 'shared/lib/types';
import { PeriodGridBlobType, statusFromBackendValue } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetContractContainersWithFiltersQuery } from 'shared/api/rtkq/contractcontainers';
import { useDeleteContractMutation } from 'shared/api/rtkq/contracts';
import { useUpdateContractStatusMutation } from 'shared/api/rtkq/contractstatuses';
import { useGetAllContractsByTrialQuery } from 'shared/api/rtkq/periods';

import useContractAndPeriodWithVersions from '../../shared/hooks/useContractAndPeriodWithVersions';
import useOccVersionHistoryGridOptions from '../hooks/useOccVersionHistoryGridOptions';
import useOccVersionHistoryRows from '../hooks/useOccVersionHistoryRows';

type Props = { overlayNoRowsTemplate?: string; sx?: SxProps };
function OccVersionHistoryGrid(props: Props): ReactElement {
  const { overlayNoRowsTemplate, sx } = props;

  const trial = useSelector(selectTrial);
  const { periodTraceId } = useContractAndPeriodWithVersions();

  const { currentData: contractContainers, isFetching } =
    useGetContractContainersWithFiltersQuery({
      trial: trial.trace_id,
      vendor_type: 'OCC',
    });

  const { currentData: allContractVersions } = useGetAllContractsByTrialQuery(
    periodTraceId
      ? { trace_id: periodTraceId, otherParameter: trial.trace_id }
      : skipToken,
  );
  const [
    showStatusChangeConfirmationModal,
    setShowStatusChangeConfirmationModal,
  ] = useState(false);
  const [confirmationModalData, setConfirmationModalData] =
    useState<StatusChangeConfirmationModalData | null>(null);
  const [versionToDelete, setVersionToDelete] =
    useState<VersionHistoryRow | null>(null);

  const columnDefs = useGridColDefs(useOccVersionHistoryColDefs, [
    setShowStatusChangeConfirmationModal,
    setConfirmationModalData,
    setVersionToDelete,
  ]);
  const rowData = useOccVersionHistoryRows(
    true,
    contractContainers,
    allContractVersions,
  );
  const gridOptions = useGridOptions(useOccVersionHistoryGridOptions, [
    contractContainers,
  ]);

  const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false);
  const [updateContractStatus] = useUpdateContractStatusMutation();
  const [deleteContractVersion, { isLoading: isDeletingContractVersion }] =
    useDeleteContractMutation();
  const isContractStatusV2Enabled = useFeatureFlag('contract_status_v2');

  const onStatusChangeAccepted = () => {
    if (!confirmationModalData) {
      return;
    }

    if (!isContractStatusV2Enabled) {
      void (async () => {
        await updateContractStatus({
          trace_id: confirmationModalData.statusTraceIdForPeriod,
          status: confirmationModalData.newStatus as BackendContractStatus,
        });
      })();
    }

    setShowSuccessMsg(true);
    setShowStatusChangeConfirmationModal(false);
  };

  const onStatusChangeRejected = () => {
    setShowStatusChangeConfirmationModal(false);
  };

  const isFullWidthRow = useCallback(
    (params: IsFullWidthRowParams) => params.rowNode.data?.create_new === true,
    [],
  );

  const handleCloseModal = () => setVersionToDelete(null);

  const handleContractVersionDelete = async () => {
    const traceId = versionToDelete?.contract_version_trace_id;
    if (traceId) {
      await deleteContractVersion(traceId);
    }
    handleCloseModal();
  };

  // if contractContainers is not yet defined (or re-fetching data), don't render the
  // grid as we have to pass the contract containers to the custom grouping cell
  // in grid options and ag-grid won't honor changes after render
  if (contractContainers === undefined || isFetching) {
    return <div />;
  }

  return (
    <>
      {showStatusChangeConfirmationModal && confirmationModalData && (
        <ConfirmToChangeStatus
          contractVersionTraceId={confirmationModalData.contractVersionTraceId}
          existingEffectiveDate={confirmationModalData.existingEffectiveDate}
          existingVoidedDate={confirmationModalData.existingVoidedDate}
          contractContainerTraceId={
            confirmationModalData.contractContainerTraceId
          }
          currentStatus={statusFromBackendValue(
            confirmationModalData.currentStatus as BackendContractStatus,
          )}
          existingAipEffectiveDate={
            confirmationModalData.existingAipEffectiveDate
          }
          newStatus={statusFromBackendValue(
            confirmationModalData.newStatus as BackendContractStatus,
          )}
          onClose={onStatusChangeRejected}
          onConfirm={onStatusChangeAccepted}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={4000}
        open={showSuccessMsg}
        onClose={() => setShowSuccessMsg(false)}
      >
        <Alert severity="success">Contract status changed.</Alert>
      </Snackbar>
      <CondorAgGrid
        columnDefs={columnDefs}
        fullWidthCellRenderer="AgGridOccContractContainerAddNewCellRenderer"
        gridOptions={gridOptions}
        isFullWidthRow={isFullWidthRow}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        rowData={rowData}
        sx={sx}
      />
      {versionToDelete && (
        <Modal
          handleClose={handleCloseModal}
          title={`Are you sure you want to delete ${versionToDelete.version_name?.length ? versionToDelete.version_name : `this ${versionToDelete.version}`} for ${contractContainers.find(({ trace_id }) => trace_id === versionToDelete.contract_container_trace_id)?.vendor_name}?`}
          ButtonProps={{
            label: 'Delete Contract Version',
            testId: 'DeleteCROContractVersionModal',
            onClick: () => void handleContractVersionDelete(),
            sx: { backgroundColor: 'error.main' },
            loading: isDeletingContractVersion,
          }}
          isOpen
        >
          <Typography>
            This will delete the contract record, budget, and files for this
            version. If you are deleting the current contract, this will delete
            any expenses that were being used in the trial&apos;s accrual and
            forecasting calculations. This action cannot be undone.
          </Typography>
        </Modal>
      )}
    </>
  );
}

export default withPeriodSpecificGridWrapper(
  OccVersionHistoryGrid,
  PeriodGridBlobType.OCC_VERSION_HISTORY,
);
